<template>
  <div>
    <div class="mb-6">
      <a
        class="text-decoration-none text-subtitle-2 font-weight-bold"
        @click.prevent="returnBack"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </a>
    </div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :total-items="totalItems"
          :items="sarprasTools"
          :headers="headers"
          :page="page"
          :search="search"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          :title="titleCard"
          subtitle="Halaman untuk menambahkan alat pada sarpras "
          add-btn="Tambah Alat"
          @delete-item="confirmDestroy"
          @edit-item="showFormEdit"
          @add-item="showFormAdd"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Alat': 'Edit Alat'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template
        v-slot:body
      >
        <v-form ref="form">
          <v-row class="py-5 px-5">
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="6"
            >
              <div>
                <v-autocomplete
                  v-model="sarprasTool.type_tool"
                  :items="types"
                  label="Jenis Alat"
                  dense
                  clearable
                  hide-details="none"
                  outlined
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="6"
            >
              <div>
                <v-text-field
                  v-model="sarprasTool.name_tool"
                  label="Nama Alat"
                  dense
                  value
                  outlined
                ></v-text-field>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sarprastool
              sm="12"
              cols="12"
            >
              <div>
                <v-text-field
                  v-model="sarprasTool.specification"
                  label="Spesifikasi"
                  dense
                  value
                  outlined
                ></v-text-field>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              cols="12"
            >
              <div>
                <v-text-field
                  v-model="sarprasTool.ownership"
                  label="Kepemilikan"
                  dense
                  value
                  outlined
                ></v-text-field>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              cols="12"
            >
              <div>
                <v-text-field
                  v-model="sarprasTool.total"
                  label="Jumlah Total"
                  type="number"
                  dense
                  value
                  outlined
                ></v-text-field>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              cols="12"
            >
              <div>
                <v-text-field
                  v-model="sarprasTool.eligible"
                  label="Jumlah Laik"
                  type="number"
                  dense
                  value
                  outlined
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Alat"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    ></ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { integerValidator, required } from '@core/utils/validation'
import { mdiArrowLeft } from '@mdi/js'

export default {
  name: 'SarprasTool',
  components: {
    MainCard,
    ModalDialog,
    ConfirmDialog,
    SkeletonLoaderTable,
    DataTablePagination,
  },
  data() {
    return {
      icons: {
        mdiArrowLeft,
      },
      dialog: '',
      search: '',
      width: 1000,
      page: 1,
      totalItems: 0,
      totalPages: 0,
      modalDialog: false,
      confirmDialog: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      validation: {
        required,
        integerValidator,
      },
      types: [
        { text: 'Alat', value: 'tool' },
        { text: 'Angkutan', value: 'transport' },
        { text: 'Buku', value: 'book' },
      ],
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Jenis Alat', value: 'toolType' },
        { text: 'Nama Alat', value: 'name_tool' },
        { text: 'Spesifikasi', value: 'specification' },
        { text: 'Kepemilikan', value: 'ownership' },
        { text: 'Jumlah Total', value: 'total' },
        { text: 'Jumlah Laik', value: 'eligible' },
        { text: 'Actions', value: 'actions' },
      ],
      sarprasTools: [],
      typesArray: {
        tool: 'Alat',
        transport: 'Angkutan',
        book: 'Buku',
      },
      formValid: false,
      date_out: false,
      sarprasTool: {
        sarpras_uuid: '',
        type_tool: '',
        name_tool: '',
        specification: '',
        ownership: '',
        total: '',
        eligible: '',
      },
      service: 'sarprastool',
      titleCard: '',
      sarprasName: {},
      school_uuid: '',
      students: [],
      formData: [],
      filterQuery: {
        search: '',
      },
    }
  },
  watch: {
    page: {
      handler() {
        this.listTool(this.$route.params.uuid, this.filterQuery)
      },
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.getSarpras(this.$route.params.uuid)
    this.listTool(this.$route.params.uuid)
  },
  methods: {
    resetForm() {
      this.sarprasTool.type_tool = ''
      this.sarprasTool.name_tool = ''
      this.sarprasTool.specification = ''
      this.sarprasTool.ownership = ''
      this.sarprasTool.total = ''
      this.sarprasTool.eligible = ''
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        this.school_uuid = el.uuid
        this.sarprasTool.school_uuid = this.school_uuid
      })

      return true
    },
    async getSarpras() {
      await this.$services.ApiServices.get('sarpras', this.$route.params.uuid).then(
        ({ data }) => {
          this.titleCard = `Alat di ${data.data.name}`
        },
        err => console.error(err),
      )
    },
    async listTool(uuid, params = {}) {
      await this.$services.ApiServices.list(this.service, {
        ...params,
        sarpras_uuid: uuid,
      }).then(
        ({ data }) => {
          this.sarprasTools = data.data.map((sarprasTool, index) => ({
            ...sarprasTool,
            toolType: this.typesArray[sarprasTool.type_tool],
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },
    async add() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.$data.sarprasTool.sarpras_uuid = this.$route.params.uuid
      Object.entries(this.$data.sarprasTool).forEach(([key, item]) => {
        this.formData.append(key, item)
      })
      await this.$services.ApiServices.add(this.service, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      await this.listTool(this.$route.params.uuid, this.filterQuery)
      this.resetForm()
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.modalDialog = true
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.sarprasTool = data.data
      })
    },
    async update() {
      console.log('ok update')
      this.isLoadingButton = true
      this.formData = new FormData()
      Object.entries(this.$data.sarprasTool).forEach(([key, item]) => {
        this.formData.append(key, item)
      })
      await this.$services.ApiServices.update(this.service, this.formData, this.sarprasTool.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.listTool(this.$route.params.uuid, this.filterQuery)
      this.resetForm()
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.sarprasTool.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('sarprasTtool', this.sarprasTool.uuid).then(
        ({ data }) => {
          this.listTool(this.$route.params.uuid, this.filterQuery)
          this.showSnackbar({
            text: data.result.message,
            color: 'error',
          })
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.confirmDialog = false
    },
    async searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      await this.listTool(this.$route.params.uuid, this.filterQuery)
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
</style>
